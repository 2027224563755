import { Component } from '@angular/core';
import { CookieConsentService } from 'src/app/shared';

@Component(
{
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  mailchimpSubscribed = false;

  constructor(protected readonly cookieConsentService: CookieConsentService) {}
}
