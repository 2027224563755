// Reference: https://matcha.fyi/google-analytics-angular/

import { Injectable } from '@angular/core';
import { CookieConsentService } from './cookie-consent.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  public readonly trackingCode = 'G-3B8EX36F0J';

  constructor(private readonly cookieConsentService: CookieConsentService) {}

  /** Trigger an event according to https://developers.google.com/analytics/devguides/collection/gtagjs/events */
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: number | null = null,
  ) {
    gtag('event', eventName, { eventCategory, eventLabel, eventAction, eventValue });
  }

  public clearCookies(): void {
    this.cookieConsentService.deleteCookie("_ga");
    this.cookieConsentService.deleteCookie(`_ga_${ this.trackingCode.split('-')[1] }`);
  }
}
