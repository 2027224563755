<core-header class="fixed-top"></core-header>
<div id="page" class="d-flex flex-column">
  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <core-footer></core-footer>
</div>
<app-cookie-dialog></app-cookie-dialog>
<google-analytics-gtag
  *ngIf="cookieConsentService.accepted$ | async"
></google-analytics-gtag>
