<ngb-toast
  class="bg-dark text-white"
  *ngIf="isPlatformBrowser && !this.closePopup"
  [autohide]="false"
  (hidden)="deny()"
>
  <div class="text-start p-4 d-flex flex-column">
    <h4 i18n="cookies-title|Cookies title@@core.cookies-dialog.cookies-title">
      Cookies
    </h4>
    <p
      class="smallerText text-start"
      i18n="cookies-text|Cookies text@@core.cookies-dialog.cookies-text"
    >
      At Aposphere, we believe in respecting your privacy and safeguarding your
      personal information. In order to provide you with the best possible
      experience on our website, we use cookies and similar technologies.
    </p>
    <p
      class="smallerText text-start"
      i18n="
        cookies-list-header|Cookies
        list-header@@core.cookies-dialog.cookies-list-header"
    >
      We use cookies for various purposes, including:
    </p>
    <ul>
      <li
        i18n="
          cookies-analytical|Cookies
          analytical@@core.cookies-dialog.cookies-analytical"
      >
        Analytical cookies: We use these cookies to analyze how visitors
        interact with our website, which helps us understand and improve its
        performance. These cookies provide us with anonymous data and insights
        into areas such as page views, traffic sources, and popular content.
      </li>
      <!--
            Could add other types here:
            Essential Cookies: These cookies are necessary for the basic functioning of our website and enable core features such as page navigation and security.
            Analytical Cookies: We use these cookies to analyze how visitors interact with our website, which helps us understand and improve its performance. These cookies provide us with anonymous data and insights into areas such as page views, traffic sources, and popular content.
            Personalization Cookies: These cookies allow us to tailor your experience on our website by remembering your preferences, such as language settings or personalized content suggestions.
            Advertising Cookies: We may work with advertising partners who use cookies to deliver personalized ads based on your interests. These cookies help us show you relevant advertisements that may be of interest to you.
          -->
    </ul>
    <p
      class="smallerText text-start"
      i18n="
        cookies-list-conclusion|Cookies
        list-conclusion@@core.cookies-dialog.cookies-list-conclusion"
    >
      By continuing to use our website, you consent to the use of cookies as
      described in our <a routerLink="/privacy">Privacy Statement</a>. Thank you
      for your trust and understanding.
    </p>
    <div class="text-end">
      <button
        type="button"
        (click)="deny()"
        class="btn btn-outline-light mr-3 mx-2 smallerText"
        id="btnDeny"
        i18n="cookies-reject|Cookies reject@@core.cookies-dialog.cookies-reject"
      >
        Reject
      </button>
      <button
        type="button"
        (click)="accept()"
        class="btn btn-light mx-2 smallerText"
        id="btnAccept"
        i18n="cookies-accept|Cookies accept@@core.cookies-dialog.cookies-accept"
      >
        Accept
      </button>
    </div>
  </div>
</ngb-toast>
