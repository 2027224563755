import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {

  public readonly openSettingsDialog$ = new Subject<void>();

  public readonly accepted$ = new BehaviorSubject<boolean>(false);

  public deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${ d.toUTCString() }`;
    const cpath: string = path ? `; path=${ path }` : '';
    document.cookie = `${ name }=${ value }; ${ expires }${ cpath }`;
  }
}
