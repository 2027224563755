import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from '@aposphere/ng-aposphere';
import { Subscription } from 'rxjs';
import { CookieConsentService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe()
export class AppComponent implements AfterViewInit {

  subscriptions = new Subscription();

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    public readonly router: Router,
    protected readonly cookieConsentService: CookieConsentService,
  ) {
  }

  ngAfterViewInit(): void {
    // navigate to true domain
    const aliases = ['aposphere.ch', 'aposphere.io'];
    const redirectTo = 'aposphere.com';
    if (isPlatformBrowser(this.platformId)) {
      if (aliases.includes(window.location.hostname)) window.location.hostname = redirectTo;
    }
  }
}
