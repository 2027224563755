import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from '@aposphere/ng-aposphere';
import { Subscription } from 'rxjs';
import { CookieConsentService, GoogleAnalyticsService } from 'src/app/shared';

@AutoUnsubscribe()
@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
  providers: [GoogleAnalyticsService],
})
export class CookieDialogComponent implements OnInit {

  subscriptions = new Subscription()

  isPlatformBrowser = isPlatformBrowser(this.platformId);

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly cookieConsentService: CookieConsentService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  public closePopup = false;


  ngOnInit() {
    // get stored consent
    if (isPlatformBrowser(this.platformId)) {
      const storedDecision = localStorage.getItem('cookies_consented');
      if (storedDecision === 'true') this.accept();
      else if (storedDecision === 'false') this.deny();

      this.subscriptions.add(
        this.cookieConsentService.openSettingsDialog$.subscribe(() => this.closePopup = false),
      );
    } else console.log("localStorage not available if not in browser");
  }

  public deny(): void {
    this.cookieConsentService.accepted$.next(false);
    this.googleAnalyticsService.clearCookies();
    this.storeDecision(false);
    this.closePopup = true;
  }

  public accept(): void {
    this.storeDecision(true);
    this.cookieConsentService.accepted$.next(true);
    this.closePopup = true;
  }

  private storeDecision(value: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cookies_consented', value ? 'true' : 'false');
    } else console.log("localStorage not available if not in browser");
  }
}
