import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'concept',
    loadChildren: () => import('./modules/concept/concept.module').then(m => m.ConceptModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
  },
  {
    path: 'use-cases',
    loadChildren: () => import('./modules/use-cases/use-cases.module').then(m => m.UseCasesModule),
  },
  {
    path: 'tos',
    loadChildren: () => import('./modules/tos/tos.module').then(m => m.TosModule),
  },
  {
    path: 'app/tos',
    loadChildren: () => import('./modules/tos/tos.module').then(m => m.TosModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modules/privacy-statement/privacy-statement.module').then(m => m.PrivacyStatementModule),
  },
  {
    path: 'app/privacy',
    loadChildren: () => import('./modules/privacy-statement/privacy-statement.module').then(m => m.PrivacyStatementModule),
  },
  {
    path: 'legal',
    loadChildren: () => import('./modules/legal-notice/legal-notice.module').then(m => m.LegalNoticeModule),
  },



  {
    path: '**',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 60],
    initialNavigation: 'enabledBlocking',
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
